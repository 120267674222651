






































import Vue from 'vue'
import onlyNumbers from '@/utils/onlyNumbers'
import TabSwitcher from '@/components/TabSwitcher.vue'

export default {
  name: 'MainBudgetSettings',
  components: {
    TabSwitcher
  },
  props: {
    budget_types: {
      type: Array
    },
    budget_type: {
      type: Object,
      default: () => {
        return {
          id: 0
        }
      }
    },
    currency: {
      type: String
    },
    budgetValue: {
      required: true
    },
    minBudget: {
      type: Number
    },
    distributionCheck: {
      type: Boolean
    },
    agreement: {
      default: ''
    },
    blockOptions: {
      default: false
    }
  },
  computed: {
    budget: {
      get () {
        return this.budgetValue
      },
      set (v) {
        this.$emit('change-budget', v)
      }
    }
  },
  methods: {
    changeBudgetType (data) {
      this.$emit('change-budget-type', data)
    },
    onlyNumbers
  }
}
