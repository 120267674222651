











































import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
  props: ['adSet', 'reachSize'],
  data () {
    return {
      audienceSize: true
    }
  },

  computed: {
    ...mapGetters('adsManager', [
      'getLocales'
    ]),
    hasExclusion () {
      return this.getExcludedLocation || this.getExcludedTargeting
    },
    getExcludedLocation () {
      if (this.adSet.formatted_targeting.locations) {
        return this.getLocations(this.adSet.formatted_targeting.locations.filter(location => location.is_included === false))
      }
      return ''
    },
    getExcludedTargeting () {
      return this.getTargeting(this.adSet.formatted_targeting.exclusion_targeting, 'ex')
    },
    getIncludedDemographic () {
      return this.getDemographic(this.adSet.formatted_targeting)
    },
    getIncludedTargeting () {
      return this.getTargeting(this.adSet.formatted_targeting.inclusion_targeting, 'in')
    },
    getLanguages () {
      return this.getLocales.filter(l => this.adSet.formatted_targeting.locales.includes(l.key))
        .map(location => location.name).join(', ')
    }
  },

  methods: {
    getLocations (locations) {
      if (locations.length > 0) {
        return locations.map(location => {
          let fullLocation = ''
          fullLocation += location.region ? location.region + ', ' : ''
          fullLocation += location.name ? location.name : ''
          fullLocation += location.radius ? '( +' + location.radius + 'miles)' : ''
          return fullLocation
        }).join('; ')
      } else return ''
    },
    getDemographic (formattedTargeting) {
      const ageMin: string = formattedTargeting.age_min
      const ageMax: string = formattedTargeting.age_max
      const gender: string = this.checkGender(formattedTargeting.genders)
      return `${ageMin} - ${ageMax}, ${gender}`
    },

    getTargeting (interests, type: string) {
      if (type === 'in') {
        if (interests) {
          const result = []
          interests.forEach((i) => {
            return i.interests.forEach((interes) => result.push(interes.name))
          })
          return result.join('; ')
        } else return ''
      } else {
        if (interests) {
          const result = []
          interests.interests.forEach((interes) => result.push(interes.name))
          return result.join('; ')
        } else return ''
      }
    },

    checkGender (genderCode) {
      if (genderCode === 0) return 'Male and Female'
      if (genderCode === 1) return 'Male'
      if (genderCode === 2) return 'Female'
    }
  }
})
