




































import Vue from 'vue'

import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'

export default Vue.extend({
  name: 'ConversionOptions',
  components: {
    VSelect
  },
  data () {
    return {
      conversionsOpen: true
    }
  },
  props: {
    attributionSpecOptions: {
      type: Array
    },
    attributionSpecValue: {
      type: Object
    },
    disableOptions: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    attributionSpec: {
      get () {
        return this.attributionSpecValue
      },
      set (v) {
        this.$emit('changeAttributionSpec', v)
      }
    }
  },
  methods: {
    checkCheckedSpec (option: { code: string, label: string }, attributionSpec: { code: string, label: string }): boolean {
      return (option?.label === attributionSpec?.label)
    }
  }
})
