

































import Vue from 'vue'

import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'

export default Vue.extend({
  name: 'Optimizing',
  components: {
    VSelect
  },
  props: {
    pay_for_value: {
      type: Object
    },
    optimize_for_value: {
      type: Object
    },
    payList: {
      type: Array
    },
    optimizeOptions: {
      type: Array
    }
  },
  computed: {
    optimize_for: {
      get () {
        return this.optimize_for_value
      },
      set (v) {
        this.$emit('change-optimize-for', v)
      }
    },
    pay_for: {
      get () {
        return this.pay_for_value
      },
      set (v) {
        this.$emit('change-pay-for', v)
      }
    }
  }
})
