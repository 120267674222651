

































































































import Vue from 'vue'
import BaseTimePicker from '@/components/BaseComponents/BaseTimePicker/BaseTimePicker.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
export default Vue.extend({
  name: 'BudgetScheduler',
  components: {
    BaseTimePicker,
    DatePicker
  },
  data () {
    return {
      showStartDate: false,
      showEndDate: false
    }
  },
  props: {
    budget_type_time: {
      default: ''
    },
    checkPeriodTime: {
      type: Boolean
    },
    start_time: {
      default: ''
    },
    end_time: {
      default: ''
    },
    set_start_date: {
      default: ''
    },
    set_end_date: {
      default: ''
    },
    blockStartDate: {
      type: Boolean,
      default: false
    },
    mode: {
      default: 'publication',
      validator: function (value) {
        return ['publication', 'editing'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    startDate: {
      get () {
        return this.set_start_date
      },
      set (v) {
        this.$emit('changeStartDate', v)
      }
    },
    endDate: {
      get () {
        return this.set_end_date
      },
      set (v) {
        this.$emit('changeEndDate', v)
      }
    }
    // showStartDate: {
    //   get () {
    //     return this.showTimePickerStartDate
    //   },
    //   set (v) {
    //     this.$emit('change-show-start-date', v)
    //   }
    // },
    // showEndDate: {
    //   get () {
    //     return this.showTimePickerEndDate
    //   },
    //   set (v) {
    //     this.$emit('change-show-end-date', v)
    //   }
    // }
  },
  methods: {
    getTime (time, key) {
      if (time !== null) {
        const hours = this.$moment(time, ['HH:mm:ss']).format('hh')
        const minutes = this.$moment(time, ['HH:mm:ss']).format('mm')
        const format = this.$moment(time, ['HH:mm:ss']).format('a')
        if (key === 'hours') return hours
        if (key === 'minutes') return minutes
        if (key === 'format') return format.toUpperCase()
      }
    },
    formattedTime (time) {
      return time ? this.$moment(time, ['HH:mm:ss a']).format('hh:mm:ss A') : ''
    },
    changeTime (key, time) {
      this.$emit('change-time', { key, time })
    },
    // *функция, которая запрещает выбрать дату до выбранной startDate
    beforeDisabledEndDate (date: Date): boolean {
      return (
        date <
        new Date(
          this.$moment(this.startDate)
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        )
      )
    },
    // * функция, которая запрещает выбрать дату до сегодняшнего дня,
    disabledStartDate (date: Date): boolean {
      return (
        date <
        new Date(
          this.$moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        )
      )
    },
    showTimePicker (key) {
      if (this.startDate !== null && key === 'startTime') this.showStartDate = true
      if (this.startDate !== null && this.endDate !== null && key === 'endTime') this.showEndDate = true
    }
  }
})
