












import Vue from 'vue'
import TargetingBlock from './TargetingBlock.vue'
export default Vue.extend({
  name: 'BudgetModalAdSet',
  components: {
    TargetingBlock
  },
  props: ['adSet', 'showTargeting']
})
