



































import Vue from 'vue'
import BaseInput from '../../../../components/BaseInput.vue'
export default Vue.extend({
  name: 'FrequencyCap',
  components: {
    BaseInput
  },
  props: {
    objective: {
      type: String
    },
    max_frequency_value: {
      type: Number
    },
    interval_days_value: {
      type: Number
    },
    blockOptions: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    max_frequency: {
      get () {
        return this.max_frequency_value
      },
      set (v) {
        this.$emit('change-max-frequency', { value: v })
      }
    },
    interval_days: {
      get () {
        return this.interval_days_value
      },
      set (v) {
        this.$emit('change-interval-days', { value: v })
      }
    }
  }
})
