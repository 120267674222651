



























































































import Vue from 'vue'

import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'Bidding',
  components: {},
  data () {
    return {
      // bid_cap: ''
    }
  },
  props: {
    biddingStrategy: {
      type: String
    },
    bidCap: {
      default: ''
    },
    currency: {
      type: String
    },
    budget: {
      type: Number
    },
    blockOptions: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    // bid_cap (v) {
    //   if (this.bid_cap === '') {
    //     this.bid_cap = 0
    //   } else {
    //     localStorage.setItem(`budgetBidcap${this.idCampaign}`, this.bid_cap)
    //   }
    //   if (Number(this.bid_cap) > Number(this.budget)) {
    //     this.bid_cap = this.budget
    //   }
    //   if (this.bid_cap !== null) {
    //     this.checkInteger('bid_cap')
    //     this.$emit('change-bid-cap', v)
    //   }
    // }
  },
  computed: {
    ...mapGetters('budget', [
      'getCheckBidding'
    ]),
    bid_cap: {
      get () {
        if (this.bidCap > this.budget) {
          return this.budget
        }
        return this.bidCap || 0
      },
      set (v) {
        this.$emit('change-bid-cap', v)
      }
    },
    biddingStrategyValue: {
      get () {
        return this.biddingStrategy
      },
      set (v) {
        this.$emit('change-bidding-strategy', v)
      }
    }
  },
  methods: {
    // * Меняем переданное поле, если оно отрицательное / не имеет валидный вид
    checkInteger (field: string) {
      // * проверяем и редактируем, чтобы пользователь не ввел неправильно число например 00.10.1 будет 0.1
      if (this[field][0] === '0' && this[field][1] === '0') {
        this[field] = `${Math.abs(this[field])}`
      }

      // * если пользователь вводит отрицательное число, то меняем его на это же число с положительным знаком
      if (Number(this[field]) < 0) {
        this[field] = `${Math.abs(this[field] * -1)}`
      }
    }
  }
})
