

























































































































































import Vue from 'vue'
import onlyNumbers from '@/utils/onlyNumbers'
import TargetingBlock from './TargetingBlock.vue'
import BudgetModalAdSet from './BudgetModalAdSet.vue'
import Modal from '@/components/Modal.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'

import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'BudgetModal',
  components: {
    BudgetModalAdSet,
    VSelect,
    TargetingBlock,
    Modal
  },
  props: {
    mode: {
      default: 'publication',
      validator: function (value) {
        return ['publication', 'editing'].indexOf(value) !== -1
      }
    },
    budgetData: { required: true },
    optimize_for: { required: true },
    budget: { required: true },
    currency: String,
    adSetSpendCapData: { required: true },
    budgetOptimization: Boolean,
    budgetType: String
  },

  computed: {
    ...mapGetters('budget', [
      'getOptimizationType',
      'getOptimizationTypes',
      'getMinBudgetAdSet'
    ]),
    optimizationType: {
      get () {
        return this.getOptimizationType
      },
      set (v) {
        this.setOptimizationType(v)
      }
    },
    checkAdsetsBudgets () {
      return !this.adSetSpendCapData
        .map(v => v.amount)
        .find(amountValue => {
          return this.getMinBudgetAdSet(this.budgetData, this.optimize_for.code) > amountValue
        })
    },
    adSetBudget (): number {
      if (this.budgetOptimization === true) {
        return this.adSetSpendCapData
          .filter(adSet => adSet.max !== null)
          .map(adSet => adSet.max)
          .reduce((sum, elem) => Number(sum) + Number(elem))
      } else {
        return this.adSetSpendCapData
          .filter(adSet => adSet.amount !== null)
          .map(adSet => adSet.amount)
          .reduce((sum, elem) => Number(sum) + Number(elem))
      }
    }
  },

  data () {
    return {
      showingTargeting: null
    }
  },

  watch: {
    budget () {
      this.optimizationType = null
      this.optimizationType = {
        label: 'Evenly across all AdSets',
        value: 'evenly'
      }
    },
    budgetData () {
      this.optimizationType = null
      this.optimizationType = {
        label: 'Evenly across all AdSets',
        value: 'evenly'
      }
    },
    // watcher на тип оптимизации (распределение бюджета кампани по эдсетам)
    optimizationType (type) {
      // первый вариант распределяет поровну между эдсетами
      if (type.value === 'evenly') {
        const result = (Math.floor((Number(this.budget) / Number(this.adSetSpendCapData.length)) * 100) / 100).toFixed(2)
        this.adSetSpendCapData.forEach(adSet => (adSet.amount = result))
      }
      // второй вариант - пропорциональное распределение на основе размера аудитории эдсета
      if (type.value === 'proportionally' || this.adSetSpendCapData.map(v => v.audience_size).find(v => v === 0)) {
        // подсчитываем сумму аудиторий эдсетов между которыми распределяем сумму
        const getSumAudienceSize = (adSets) => {
          return adSets.reduce(
            (acc, cur) => {
              return Number(acc) + Number(cur.audience_size)
            }, 0)
        }
        // считаем бюджет эдсета
        const amountCount = (budget, adSet, sumAudienceSize) => {
          return (
            Number(budget) / 100 * Number((adSet.audience_size / sumAudienceSize * 100))
          ).toFixed(2)
        }
        // если после распределения некоторые эдсеты получили бюджет меньше минимально возможного (устанавливается в backend)
        // присваиваем таким эдсетам значени минимального бюджета
        this.adSetSpendCapData.forEach(adSet => {
          adSet.amount = amountCount(this.budget, adSet, getSumAudienceSize(this.adSetSpendCapData))
          if (adSet.amount < this.getMinBudgetAdSet(this.budgetData, this.optimize_for.code)) {
            adSet.amount = this.getMinBudgetAdSet(this.budgetData, this.optimize_for.code)
          }
        })
        const minimals = this.adSetSpendCapData.filter(v => v.amount === this.getMinBudgetAdSet(this.budgetData, this.optimize_for.code))
        // эдсеты которые получили бюджет не меньше минимально возможного (устанавливается в backend)
        const notMinimals = this.adSetSpendCapData.filter(v => v.amount > this.getMinBudgetAdSet(this.budgetData, this.optimize_for.code))
        // еще раз проходим по эдсетам и перераспределяем бюджет оставшийся после присвоемния эдсетам
        // (у которых бюджет стал меньше минимально возможного) минимального бюджета
        this.adSetSpendCapData.forEach((adSet, index) => {
          if (!!minimals.length && adSet.amount > this.getMinBudgetAdSet(this.budgetData, this.optimize_for.code)) {
            this.adSetSpendCapData[index].amount = amountCount(
              this.budget - minimals.length * this.getMinBudgetAdSet(this.budgetData, this.optimize_for.code),
              this.adSetSpendCapData[index],
              getSumAudienceSize(notMinimals)
            )
          }
        })
      }
      this.$emit('distributionType', type)
    }
  },

  methods: {
    ...mapActions('budget', [
      'update_adSet_spendCap',
      'setOptimizationType'
    ]),
    ...mapActions('notifications', [
      'addNotification'
    ]),
    selectOptimizationType (acc) {
      if (!(this.adSetSpendCapData.map(v => v.audience_size).filter(v => v <= 0).length && acc.value === 'proportionally')) {
        this.optimizationType = acc
        this.$emit('change-optimization-type')
      }
    },
    onlyNumbers,
    setAdSetBudgets () {
      if (this.checkAdsetsBudgets) {
        this.$modal.hide('budget-modal')
      } else {
        this.addNotification({
          body: 'The budget of one of the adsets is less than the minimum allowable',
          type: 'danger'
        })
      }
    },

    checkBudget (adSetsData) {
      let result = false
      adSetsData.forEach((adSet) => {
        if (Number(adSet.amount) < Number(this.getMinBudgetAdSet(this.budgetData, this.optimize_for.code))) {
          result = true
        }
      })
      return result
    },

    hideTargeting () {
      this.showingTargeting = null
    },

    close () {
      this.$emit('close')
    },

    updatingSpendCup (updatingSpendCup) {
      if (this.budgetOptimization === true) {
        let result = true
        const resultMax = updatingSpendCup
          .map(adSet => adSet.max)
          .reduce((sum, elem) => Number(sum) + Number(elem))

        updatingSpendCup.forEach(adSet => {
          const checkBudgets =
            adSet.min === null ||
            adSet.min < adSet.minSpendTarget ||
            adSet.max === null ||
            adSet.max > adSet.maxSpendCap ||
            adSet.min > adSet.max ||
            resultMax > this.budget

          if (checkBudgets) {
            result = false
            this.addNotification({
              body:
                'You have not filled in all the fields / Limits are not met',
              type: 'danger'
            })
          }
        })

        if (result === true) {
          const requestBody = {
            ad_sets_data: [],
            budget_type: this.budgetType,
            amount: this.budget,
            use_budget_optimization: this.budgetOptimization
          }

          updatingSpendCup.forEach(adSet => {
            requestBody.ad_sets_data.push({
              id: adSet.id,
              min_spend_target: adSet.min,
              max_spend_cap: adSet.max
            })
          })
          this.update_adSet_spendCap({
            requestBody: requestBody,
            id: this.$route.params.campaign_id
          })
            .then(() => {
              this.$emit('adSetBudget', Number(this.adSetBudget))
              this.addNotification({
                body: 'Success',
                type: 'success'
              })
              this.close()
            })['catch'](error => {
              this.addNotification({
                body: `${error.data.errors[0].detail}`,
                type: 'danger'
              })
            })
        }
      }

      if (this.budgetOptimization === false) {
        let result = true

        const resultMax = updatingSpendCup
          .map(adSet => adSet.amount)
          .reduce((sum, elem) => Number(sum) + Number(elem))

        updatingSpendCup.forEach(adSet => {
          const checkBudgets = adSet.amount === null || adSet.amount < adSet.minSpendTarget
          if (checkBudgets) {
            result = false
            this.addNotification({
              body:
                'You have not filled in all the fields / Limits are not met',
              type: 'danger'
            })
          }

          if (resultMax > this.budget) {
            result = false
            this.addNotification({
              body:
                'Ad sets budget exceeds campaign budget.',
              type: 'danger'
            })
          }
        })

        if (result === true) {
          const requestBody = {
            ad_sets_data: [],
            // * тип бюджета (daily/ total)
            budget_type: this.budgetType,
            // * суммарный бюджет, который вводят в форме
            amount: this.budget,
            use_budget_optimization: this.budgetOptimization
          }

          updatingSpendCup.forEach(adSet => {
            requestBody.ad_sets_data.push({
              // * айдишник адсета
              id: adSet.id,
              // * бюджет, который задали адсету в модалке
              budget: adSet.amount
            })
          })
          this.update_adSet_spendCap({
            requestBody: requestBody,
            id: this.$route.params.campaign_id
          })
            .then(() => {
              this.$emit('adSetBudget', Number(this.adSetBudget))
              this.addNotification({
                body: 'Success',
                type: 'success'
              })
              this.close()
            })['catch'](error => {
              this.addNotification({
                body: `${error.data.errors[0].detail}`,
                type: 'danger'
              })
            })
        }
      }
    }
  },
  mounted () {
    if (!this.getOptimizationType) {
      this.optimizationType = {
        label: 'Evenly across all AdSets',
        value: 'evenly'
      }
    }
  }
})
